import React from 'react';
import styled from "styled-components";

import { HomeHeader } from "../components/Headers/HomeHeader";
import { SEO, Layout, Footer } from "../components";
import BodyHeader from '../components/Headers/BodyHeader';
import RichText from '../components/RichText';

const PageBody = styled.div`
	background-color: #ffffff;
	padding: 4rem 0;
  width: 100%;
`

type PageProps = {
	pageContext: {
		page: {
			slug: string,
			title: string,
			seoDescription?: string,
			body: {
				raw: string
			},
			socialShareThumbnail: {
				file: {
					url: string
				}
			}
		}
	};
}

export default function Page({ pageContext }: PageProps) {
	const { title, slug, body, seoDescription, socialShareThumbnail } = pageContext.page;

	return (
		<Layout
			header={<HomeHeader style={{ marginBottom: 0 }} />}
			footer={<Footer />}
		>
			<SEO
				title={title}
				desc={seoDescription}
				image={socialShareThumbnail.file.url}
			/>
			<BodyHeader title={title} />
			<PageBody>
				<div className="container">
					<RichText content={body} />
				</div>
			</PageBody>
		</Layout >
	)
}
