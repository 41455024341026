import React from "react";
import styled from "styled-components"

type BodyHeaderProps = {
	title: string,
};

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;

	h1 {
		text-transform: uppercase;
		font-weight: 800;
    margin-bottom: 0;
    font-family: inherit;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.9);
	}
`;

const Breadcrumbs = styled.div`
	display: flex;

	@media (max-width: 576px) {
		display: none;
	}

	span {
		font-family: Lato;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 42px;
		text-align: right;
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;

		svg {
			margin: 0 0.3rem;
		}

		&:last-child {
			color: #bda06d;
		}
	}
`


export default function BodyHeader({ title }: BodyHeaderProps) {
	return (
		<HeaderWrapper className="container">
			<h1>{title}</h1>
			<Breadcrumbs>
				<span>Home</span>
				<span>
					<svg
						width="8"
						height="8"
						viewBox="0 0 8 8"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M3.49792 -0.000114648L0 3.4978L3.53553 7.03334L7.03345 3.53542L3.49792 -0.000114648Z"
							fill="#BDA06D"
						/>
					</svg>
				</span>
				<span>{title}</span>
			</Breadcrumbs>
		</HeaderWrapper >
	);
}
